import { useTranslation } from "react-i18next";
import { Skeleton, Spin, message } from "antd";

import QPageLayout from "components/layout/QPageLayout";
import { UseMutationResult, useMutation, useQuery } from "react-query";
import {
  getFixedAssetAddition,
  updateFixedAssetAddition,
} from "api/FixedAssetAdditions";
import { AxiosError } from "axios";
import { ApiError } from "types/Error";
import BackButton from "components/layout/BackButton";
import { FixedAssetAdditionMergeForm } from "components/tenant/FixedAssetAdditions/Merge";
import { FixedAssetAddition } from "types/FixedAssetAddition";
import { FixedAssetAdditionLinkBillForm } from "../LinkBill";
import { FixedAssetAdditionCreateBillForm } from "../CreateBill";
import { fixedAssetAdditionKeys } from "tokens/query-keys";
import { DisabledContextProvider } from "antd/lib/config-provider/DisabledContext";
import RecordContext from "contexts/RecordContext";
import { usePermission } from "hooks/index";

const EditForm = ({
  record,
  mutation,
  disabled = false,
}: {
  record: FixedAssetAddition;
  mutation: UseMutationResult<
    FixedAssetAddition,
    AxiosError<ApiError>,
    HTMLFormElement,
    unknown
  >;
  disabled?: boolean;
}) => {
  const billPermission = usePermission("bill");

  if (record.addition_type === "asset_merge") {
    return <FixedAssetAdditionMergeForm mutation={mutation} />;
  } else if (record.addition_type === "link_bill" && billPermission?.read) {
    return <FixedAssetAdditionLinkBillForm mutation={mutation} />;
  } else if (
    record.addition_type === "create_bill" &&
    (billPermission?.approve || (billPermission.read && disabled))
  ) {
    return <FixedAssetAdditionCreateBillForm mutation={mutation} />;
  }

  return <></>;
};

export const FixedAssetAdditionEdit = (props: {
  additionID: number;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const [messageAPI, msgContext] = message.useMessage();

  const { data, isLoading, isError } = useQuery<FixedAssetAddition>({
    queryKey: fixedAssetAdditionKeys.detail?.(props.additionID),
    queryFn: () => getFixedAssetAddition(props.additionID),
  });

  const breadCrumbData = [
    {
      title: (
        <a href="/tenant/fixed_assets">
          {t("activerecord.attributes.layout.fixed_assets")}
        </a>
      ),
    },
    {
      title: (
        <a href="/tenant/fixed_asset_additions">
          {t("fixed_asset_additions.layout.title")}
        </a>
      ),
    },
    {
      title: t("fixed_asset_additions.layout.title"),
    },
  ];

  const fixedAssetAdditionMutation = useMutation({
    mutationFn: updateFixedAssetAddition(Number(props.additionID)),
    onSuccess: (_data) => {
      window.location.href = "/tenant/fixed_asset_additions";
    },
    onError: (error: AxiosError<ApiError>) => {
      let errorMsg = t("forms.messages.error");
      if (error.response?.data?.message?.errors) {
        errorMsg = error.response?.data?.message?.errors;
      }
      messageAPI.open({
        type: "error",
        content: errorMsg,
      });
    },
  });

  if (!data) {
    return <Skeleton />;
  }

  const { disabled } = props;

  return (
    <QPageLayout
      title={t("fixed_asset_additions.layout.title")}
      actionBtns={<BackButton />}
      breadCrumbData={breadCrumbData}
      messageHolder={msgContext}
    >
      <DisabledContextProvider disabled={disabled}>
        <div className={disabled ? "show-page" : ""}>
          {isLoading || isError ? (
            <Spin size="large">
              <FixedAssetAdditionMergeForm
                mutation={fixedAssetAdditionMutation}
              />
            </Spin>
          ) : (
            <RecordContext.Provider value={data || { id: 0 }}>
              <EditForm
                mutation={fixedAssetAdditionMutation}
                record={data}
                disabled={disabled}
              />
            </RecordContext.Provider>
          )}
        </div>
      </DisabledContextProvider>
    </QPageLayout>
  );
};
