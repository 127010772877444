import { Skeleton } from "antd";
import { getCurrentUser } from "api/CurrentUser";
import { FixedAssetAdditionEdit } from "components/tenant/FixedAssetAdditions/FixedAssetAdditionEdit";
import UserContext from "contexts/UserContext";
import { useQuery } from "react-query";
import { currentUserKeys } from "tokens/query-keys";

const FixedAssetAdditionShowPage = ({
  additionID,
  disabled,
}: {
  additionID: number;
  disabled: boolean;
}) => {
  const userQuery = useQuery({
    queryKey: currentUserKeys.all,
    queryFn: getCurrentUser,
  });

  if (userQuery.isLoading || !userQuery.data) return <Skeleton />;

  return (
    <UserContext.Provider value={userQuery.data}>
      <FixedAssetAdditionEdit additionID={additionID} disabled={disabled} />;
    </UserContext.Provider>
  );
};

export default FixedAssetAdditionShowPage;
